import { getJournals, getPostCategories, getStaticPage } from '../lib/api'
import { useRouter } from 'next/router'
import last from 'lodash/last'
import find from 'lodash/find'
import { resolveInternalLinkUrl, resolveUrlWithLocal } from '../lib/resolveLink'
import PageComponent from '../components/Page'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import getFilters from '../helpers/getFilters'
import { setFilters } from '../helpers/filterUrlParams'

export default function Page (props) {
  const router = useRouter()
  const slug = router.query.slug
  const filterPageList = ['new-arrivals', 'nook', 'runners', 'samples', 'sale', 'custom']

  // if page is shop-all, check that there are filters we can apply:
  if ((slug && slug.length === 1 && slug[0] === 'shop-all') || (slug && slug.some(s => filterPageList.includes(s)))) {
    // get our available filters
    if (typeof window !== 'undefined') {
      const filters = getFilters()
      const params = new URLSearchParams(window.location.search)
      if (params) {
        const paramsArr = []
        for (const [key, value] of params.entries()) {
          paramsArr[key] = value
        }
        setFilters(filters, paramsArr)
      }
    }
  }

  return <PageComponent {...props} />
}

const getPageData = async (slug, locale, preview) => {
  return await getStaticPage(
    last(slug),
    locale,
    preview
  )
}

export const getStaticPageData = async (slug, locale, preview) => {
  const pageData = await getPageData(isEmpty(slug) ? ['home'] : slug, locale, preview)

  if (!pageData || !pageData.page) {
    return {
      notFound: true
    }
  }

  const resolvedUrl = resolveInternalLinkUrl(pageData.page)
  const currentUrl = resolveUrlWithLocal({ url: `${slug.join('/')}`, locale })

  // Remove any locale from the url
  if (currentUrl.replace(`/${locale.toLowerCase()}`, '') !== resolvedUrl.replace(`/${locale.toLowerCase()}`, '')) {
    return {
      redirect: {
        destination: resolvedUrl,
        permanent: true
      }
    }
  }

  return {
    props: {
      data: pageData
    },
    revalidate: 60 * 60 * 2 // 2 hour revalidate time. We use on-demand validation to invalidate the data
  }
}

const DIALOG_ROUTES = ['share']

export async function getStaticProps (props) {
  const { preview = false, previewData, params, locale } = props
  const { slug = [] } = params || {}

  const resolvedSlug = slug
  if (get(resolvedSlug, [0]) === '__webpack_hmr' ||
    get(resolvedSlug, [0]) === 'wp_content' ||
    get(resolvedSlug, [0]) === 'autodiscover.xml') return { notFound: true }

  if (get(resolvedSlug, [0]) === 'journal') {
    return await handleJournalRoute(resolvedSlug, locale, preview)
  }

  if (DIALOG_ROUTES.includes(get(resolvedSlug, [0]))) {
    // this will get the homepage data, these routes are dialogs
    return await getStaticPageData([], locale, preview, previewData)
  }

  return await getStaticPageData(resolvedSlug, locale, preview, previewData)
}

export async function getStaticPaths () {
  // We'll pre-render only these paths at build time.
  // { fallback: blocking } will server-render pages
  // on-demand if the path doesn't exist.
  return {
    paths: [
      { params: { slug: false } },
      { params: { slug: ['us'] }, locale: 'en-US' },
      { params: { slug: ['shop-all'] } },
      { params: { slug: ['shop-all'] }, locale: 'en-US' },
      { params: { slug: ['our-collection'] } },
      { params: { slug: ['our-collection'] }, locale: 'en-US' },
      { params: { slug: ['shop-all', 'new-arrivals'] } },
      { params: { slug: ['new-arrivals'] }, locale: 'en-US' }
    ],
    fallback: 'blocking'
  }
}

export async function handleJournalRoute (slug, locale, preview) {
  const limit = 14
  const path = slug.slice(1)
  if (path && path.length > 2) return { notFound: true }

  const [categorySlug, page = 0] = path || []

  const postCategories = await getPostCategories(locale, preview)
  let category
  let pageIndex

  if (!isNaN(categorySlug)) {
    pageIndex = parseInt(categorySlug)
  } else {
    if (categorySlug) {
      category = find(postCategories, cat => cat.slug === categorySlug)
      if (!category) {
        // If there is no category we default back to the normal page route so we can load the post.
        return getStaticPageData([slug[0], categorySlug], locale, preview)
      }
    }
    pageIndex = parseInt(page)

    if (isNaN(pageIndex) || pageIndex < 0) return { notFound: true }
  }

  const pageData = await getStaticPage(slug[0], locale, preview)
  if (!pageData || !pageData.page) {
    return {
      notFound: true
    }
  }

  const journals = await getJournals({ locale, parentSlug: pageData.page.slug, categorySlug: get(category, ['slug']), page: pageIndex, limit, preview })
  const totalPages = Math.ceil(journals.totalCount / limit)
  if (totalPages < (pageIndex + 1)) {
    return { notFound: true }
  }

  return {
    props: {
      data: {
        ...pageData,
        pagedData: {
          postCategories,
          journals: journals
        }
      }
    },
    revalidate: 60 * 60 * 2 // 2 hour revalidate time. We use on-demand validation to invalidate the data
  }
}
