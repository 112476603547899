export const IMAGE_QUERY = `
_id,
altText,
title,
assetId,
originalFilename,
metadata {
  dimensions,
  isOpaque,
  hasAlpha,
  blurHash
},
mimeType,
url,
attribution,
hotspot,
crop
`

export const VIDEO_QUERY = `
...
`

export const FILE_QUERY = `
label,
"url": asset->url
`

export const IMAGE_WITH_ASSET_QUERY = `
  ...,
  "asset": asset->{
    ${IMAGE_QUERY}
  }
`

export const PAGE_REFERENCE_QUERY = `
  _id,
  _type,
  title,
  name,
  "slug": slug.current,
  "locale": site->locale,
  layout,
  pageType,
  "category": category->{ _id, title },
  excerpt,
  "featuredImage": featuredImage {
    ${IMAGE_WITH_ASSET_QUERY}
  },
  published_date,
  "parent": parent->{
    _id,
    _type,
    title,
    "slug": slug.current
  },
  "primaryCategory": primaryCategory->{ _id, name, parentCategory },
  "categories": categories[]->{ _id, name, parentCategory },
  "room_tags": room_tags[]->{ _id, name },
  "room_style_tags": room_style_tags[]->{
    _id,
    name,
    order,
    "room_tag": room_tag->{
      _id,
      name
    },
    "icon": icon {
      ${IMAGE_WITH_ASSET_QUERY}
    }
  },
  "fibre_tags": fibre_tags[]->{ _id, name },
  "suitability_tags": suitability_tags[]->{ _id, name },
  "images": images[] {
    ${IMAGE_WITH_ASSET_QUERY}
  },
  "colors": colors[] {
    ...,
    sampleCode,
    "featureImage": featureImage { ${IMAGE_WITH_ASSET_QUERY} },
    "topShot": topShot { ${IMAGE_WITH_ASSET_QUERY}  },
    "generic_colour": generic_colour->{ _id, name },
    "colorSwatch": colorSwatch { ${IMAGE_WITH_ASSET_QUERY}  },
    "images": images[] {
      ${IMAGE_WITH_ASSET_QUERY}
    }
  },
  "customColors": customColors[] {
    ...,
    "topShot": topShot { ${IMAGE_WITH_ASSET_QUERY} }
  },
  roleType,
  location,
  "product": product{
    availability,
    calculated_price,
    id,
    inventory_level,
    is_visible,
    name,
    options,
    modifiers,
    price,
    sale_price,
    sort_order,
    "variants": variants[]{
      calculated_price,
      id,
      inventory_level,
      option_values,
      price,
      sale_price
    }
  }
`

export const CAREERS_QUERY = `
...,
roleType,
location,
${PAGE_REFERENCE_QUERY}
`

export const slices = `
  ${''
  /* References and Images will be resolved automatically.
    You can add your own custom transforms here for example:

    _type == 'slice_type' => {
      ...,
      "related": *[_type == "page"][0...5],
      "doc": doc->{ image }
    },
  */}
  true => { ${'' /* This is needed to get the other slice types. */}
    ...
  },
  _type == 'product_journal_posts_slice' => {
    ...,
    "chosenArticles": chosenArticles[]-> {
      ${PAGE_REFERENCE_QUERY}
    }
  },
  _type == 'product_details_slice' => {
    ...,
    resources[] {
      label,
      file {
        ...,
        "url": asset->url
      }
    }
  },
  _type == 'journal_related_articles' => {
    ...,
    "chosenArticles": chosenArticles[]-> {
      ${PAGE_REFERENCE_QUERY}
    },
    "relatedArticles": *[
      !(_id in path('drafts.**')) && 
      _type=="page" && 
      _id != ^._id &&
      pageType == "post"
    ] | score(
      boost(category._ref == ^.category._ref && published_date <= ^.published_date, 5),
      boost(published_date <= ^.published_date, 1)
    ) [0..2] | order(published_date desc) {
      ${PAGE_REFERENCE_QUERY}
    }
  }
`

export const JOURNALS_QUERY = (categorySlug, parentSlug) => (`
*[
  !(_id in path('drafts.**'))
  && _type == "page"
  && site->locale == $locale
  && pageType == "post"
  ${parentSlug ? '&& parent->slug.current == $parentSlug' : ''}
  ${categorySlug ? '&& category->.slug.current == $categorySlug' : ''}
]
| order(published_date desc)
{
  ${PAGE_REFERENCE_QUERY}
}`)

export const PRODUCT_QUERY = `
bigCommerceId,
slug,
sizeList,
${PAGE_REFERENCE_QUERY},
"slices": []
`

const settingsMenuItem = (slicesCount = 1) => `
...
_id,
_type,
title,
"slug": slug.current,
"locale": site->locale,
layout,
pageType,
excerpt,
color,
featuredImage,
"parent": parent->{
  _id,
  _type,
  title,
  "slug": slug.current
},
"slices": slices[0...${slicesCount}]{
  ${slices}
}`

export const settingsQuery = `
  ...,
  "bigCommerce": bigCommerce {
    ...,
    "token": null
  },
  "product": product {
    ...,
    "underlayProduct": underlayProduct->{ _id, title },
    "donationProduct": donationProduct->{ _id, title },
    "resources": resources[]{
      label,
      file {
        ...,
        "url": asset->url
      }
    }
  },
  "donations": donations {
    ...,
    "stripeSecretKey": null
  },
  "menu": menu[]{
    ...,
    "link": link->{
      slug.current == 'contact' => {
        ${settingsMenuItem(2)}
      },
      slug.current != 'contact' => {
        ${settingsMenuItem(1)}
      }
    }
  }
`

export const createPageQuery = (includeSliceQuery = true) => `
  ...,
  "slug": slug.current,
  "locale": site->locale,
  "category": category->{ _id, title },
  "featuredImage": featuredImage {
    ${IMAGE_WITH_ASSET_QUERY}
  },
  "parent": parent->{
    _id,
    _type,
    title,
    "slug": slug.current
  },
  slices[]{
    ${includeSliceQuery ? slices : '...'}
  },
  
  "images": images[] { ${IMAGE_WITH_ASSET_QUERY} },
  "colors": colors[] {
    ...,
    sampleCode,
    "featureImage": featureImage { ${IMAGE_WITH_ASSET_QUERY} },
    "topShot": topShot { ${IMAGE_WITH_ASSET_QUERY}  },
    "generic_colour": generic_colour->{ _id, name },
    "colorSwatch": colorSwatch { ${IMAGE_WITH_ASSET_QUERY}  },
    "images": images[] {
      ${IMAGE_WITH_ASSET_QUERY}
    }
  },
  "customColors": customColors[] {
    ...,
    "topShot": topShot { ${IMAGE_WITH_ASSET_QUERY} }
  },
  "primaryCategory": primaryCategory->{ _id, name, parentCategory },
  "categories": categories[]->{ _id, name, parentCategory },
  "room_tags": room_tags[]->{ _id, name },
  "room_style_tags": room_style_tags[]->{
    _id,
    name,
    order,
    "room_tag": room_tag->{
      _id,
      name
    },
    "icon": icon {
      ${IMAGE_WITH_ASSET_QUERY}
    }
  },
  "fibre_tags": fibre_tags[]->{ _id, name },
  "suitability_tags": suitability_tags[]->{ _id, name },
  relatedProducts
`
